import React from "react";

function TermAndCondition() {
  return (
    <div className="TermAndCondition flex flex-col mx-10 font-normal">
      <span>
        THESE TERMS AND CONDITIONS, together with the “Statement of Work”,
        “Proposal”, “Work Order” or “Purchase Order” (or similarly titled
        document) signed with the Customer (as defined below) which is
        incorporated hereto by reference (in each case, the{" "}
        <span className="font-bold">“SOW”</span>, and collectively – the
        <span className="font-bold">“Agreement”</span>), are entered hereto and
        shall constitute a binding agreement by and between the applicable
        RecruitGenius.ai entity signed on the SOW – CREATIVE XYZ, INC. (such
        entity, the <span className="font-bold">“Company”</span>) – and the
        counterparty executing the SOW (
        <span className="font-bold">“Customer”</span>) (Company and Customer may
        each be also referred to herein as{" "}
        <span className="font-bold">“Party”</span>, and collectively as the
        <span className="font-bold">“Parties”</span>), as of the services start
        date stated in the SOW (the{" "}
        <span className="font-bold">“Effective Date”</span>). For avoidance of
        doubt, these Terms and Conditions shall apply to and bind, any and all
        Customers who: (i) have purchased a subscription to the Company’s
        Platform and Services (as defined below) through the Company’s website
        (the <span className="font-bold">“Site”</span>) (and not by way of an
        SOW), or (ii) are receiving the Services and access to the Platform (as
        defined below) pursuant to an agreement between such Customer and an
        authorized reseller or distributor acting on behalf of the Company.
      </span>
      <h3>1. Introduction</h3>
      <span>
        Welcome to CVHide ("CVHide", "we", "us", "our"). These Terms of Service
        ("Terms") govern your access to and use of the CVHide website and
        services ("Services"). Please read these Terms carefully before using
        the Services. By accessing or using the Services, you agree to be bound
        by these Terms.
      </span>
      <h3>2. Eligibility</h3>
      <span>
        You must be at least 18 years old and have the legal capacity to enter
        into contracts in the jurisdiction in which you reside to use the
        Services. You are responsible for ensuring your compliance with all
        applicable laws and regulations.
      </span>
      <h3>3. User Accounts</h3>
      <span>
        If you choose to create an account with CVHide, you are responsible for
        maintaining the accuracy and completeness of your account information
        and for keeping your password confidential. You are responsible for all
        activity that occurs under your account. You agree to notify us
        immediately of any unauthorized use of your account or any other breach
        of security.
      </span>
      <h3>4. Acceptable Use</h3>
      <span>
        You may only use the Services for their intended purpose and in
        accordance with these Terms. You may not use the Services:
      </span>
      <ul className="list-disc list-inside">
        <li> For any illegal or unauthorized purpose. </li>
        <li> To harm or defraud others.</li>
        <li> To violate anyone's privacy or intellectual property rights. </li>
        <li> To</li>
        transmit any harmful or malicious content, including viruses or malware.
        <li> To interfere with the operation of the Services. </li>
        <li> To impersonate any</li>
        person or entity.
        <li> To attempt to gain unauthorized access to the</li>
        Services or any other CVHide systems or networks.
        <li> To use the Services</li>
        for any commercial or promotional purposes without our express written
        permission.
      </ul>
      <h3>5. Content Ownership and Use</h3>
      <span>
        You retain all ownership rights to the content you upload to the
        Services ("Your Content"). However, by uploading Your Content to the
        Services, you grant CVHide a non-exclusive, worldwide, royalty-free
        license to use, reproduce, modify, publish, and distribute Your Content
        solely for the purpose of providing the Services and for internal
        business purposes, such as improving the Services or generating
        anonymized data insights. We will never share, sell, or rent Your
        Content to any third party without your prior written consent.
      </span>
      <h3>6. Data Security and Privacy</h3>
      <span>
        We take the security of Your Content very seriously and have implemented
        appropriate technical and organizational measures to protect it from
        unauthorized access, disclosure, alteration, or destruction. Our
        detailed Privacy Policy explains how we collect, use, and disclose your
        information when you use the Services. Please review the Privacy Policy
        carefully before using the Services.
      </span>
      <h3>7. Disclaimer of Warranties</h3>
      <span>
        The Services are provided "as is" and without any warranties of any
        kind, express or implied. We disclaim all warranties, including, but not
        limited to, the implied warranties of merchantability, fitness for a
        particular purpose, and non-infringement. We do not warrant that the
        Services will be uninterrupted, error-free, or secure.
      </span>
      <h3>8. Limitation of Liability</h3>
      <span>
        To the fullest extent permitted by law, we will not be liable for any
        damages arising out of or in connection with your use of the Services,
        including, but not limited to, direct, indirect, incidental,
        consequential, and punitive damages.
      </span>
      <h3>9. Indemnification</h3>
      <span>
        You agree to indemnify and hold us harmless from and against any and all
        claims, losses, liabilities, damages, expenses, and costs (including
        reasonable attorneys' fees) arising out of or in connection with your
        use of the Services, your violation of these Terms, or your violation of
        any third-party rights.
      </span>
      <h3>10. Termination</h3>
      <span>
        We may terminate your access to the Services at any time, for any
        reason, or no reason, with or without notice. We may also terminate your
        access if you violate these Terms or if we believe your use of the
        Services is harmful to us or others.
      </span>
      <h3>11. Governing Law and Dispute Resolution</h3>
      <span>
        These Terms shall be governed by and construed in accordance with the
        laws of Vietnam, without regard to its conflict of laws provisions. Any
        dispute arising out of or relating to these Terms shall be resolved
        exclusively by the courts of Vietnam.
      </span>
      <h3>12. Entire Agreement</h3>
      <span>
        These Terms constitute the entire agreement between you and us with
        respect to the Services.
      </span>
      <h3>13. Amendments</h3>
      <span>
        We may amend these Terms at any time by posting the amended Terms on the
        Services. Your continued use of the Services after the amended Terms are
        posted constitutes your acceptance of the amended Terms.
      </span>
      <h3>14. Contact Us</h3>
      <span>
        Contact Us If you have any questions about these Terms, please contact
        us at hello@recruitgenius.ai.
      </span>
    </div>
  );
}

export default TermAndCondition;
