import { useRef, useEffect, useState } from "react";
import useMounted from "./useMounted";
/**
 * Simulate componentDidUpdate() method of Class Component
 * https://reactjs.org/docs/react-component.html#componentdidupdate
 */
const useSendCV = (
  openNotification: any
): { isLoading: boolean; sendCV: (file: Blob) => void } => {
  const mounted = useMounted();
  const [isLoading, setIsLoading] = useState(false);

  const sendCV = (file: Blob) => {
    if (mounted.current) {
      setIsLoading(true);
      try {
        let formData = new FormData();
        if (!file) {
          openNotification(
            "bottomRight",
            "file is empty",
            "Please check your input"
          );
          return;
        }
        formData.append("file", file!);
        var requestOptions = {
          method: "POST",
          body: formData,
        };

        fetch(
          `${
            import.meta.env.VITE_REACT_APP_FILE_PROCESSOR_DOMAIN
          }/parse-pdf-ocr/ocr-hide-infor-add-wartermark`,
          requestOptions
        )
          .then(async (response) => {
            console.log("ahihi");
            if (response.status !== 200) {
              throw new Error(response.status.toString());
            }
            const blob = await response.blob();

            // const file = new Blob([blob], {
            //   type: "application/pdf",
            // });

            // const link = document.createElement("a");
            // link.href = window.URL.createObjectURL(file);
            setIsLoading(false);
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "InfoHiddenCV.png";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((e: Error) => {
            setIsLoading(false);
            openNotification(
              "bottomRight",
              e.message,
              e.message === "400"
                ? "File is invalid. Please check your input"
                : `Error ${e.message}`
            );
          });
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    } else {
    }
  };

  // useEffect(() => {
  //   sendCV();
  // }, [file]);

  return { isLoading, sendCV };
};

export default useSendCV;
