import React from "react";
import Context from "@ant-design/icons/lib/components/Context";
import { Button, message, notification, Upload } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { Link, Outlet } from "react-router-dom";

import logo from "../../assets/positive-logo.svg";

function Home() {
  const [api, contextHolder] = notification.useNotification();
  return (
    <Context.Provider value={{}}>
      {contextHolder}
      <Link to={"/"} className="hidden md:block w-full h-[100px] ">
        <img src={logo} className="w-[200px] h-[60px] ml-4 mt-5" />
      </Link>
      <Link
        to={"/"}
        className="flex md:hidden justify-center w-full h-[60px] my-4"
      >
        {/* <Icon className="w-[200px] h-[40px]" component={() => <RGIcon />} /> */}
        <img src={logo} className="w-[200px] h-[60px] mt-4" />
      </Link>
      <div className="Home h-full flex flex-col justify-between">
        <Outlet />
        <div className="Footer bg-[#000000] h-[60px] hidden md:flex items-center mt-4">
          <div className="w-[100px] h-[60px]"></div>
          <span className="text-white ">
            &copy; All rights reserved. Made by RecruitGenius
          </span>
          <div className="grow" />
          <Link to={"/term-and-condition"} className="text-white ">
            Terms and conditions
          </Link>
          <div className="w-[30px]" />
          <Link to={"/privacy"} className="text-white ">
            Privacy Policy
          </Link>
          <div className="w-[100px]"></div>
        </div>
        <div className="FooterMobile bg-[#000000] h-[100px] flex md:hidden  flex-col items-center justify-center mt-4">
          <span className="text-white ">
            &copy; All rights reserved. Made by RecruitGenius
          </span>
          <Link to={"/term-and-condition"} className="text-white ">
            Terms and conditions
          </Link>

          <Link to={"/privacy"} className="text-white ">
            Privacy Policy
          </Link>
        </div>
      </div>
    </Context.Provider>
  );
}

export default Home;
