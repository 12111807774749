import React from "react";

function Privacy() {
  return (
    <div className="Privacy flex flex-col mx-10 font-normal">
      <span>
        RecruitGenius.ai (together with CREATIVE XYZ, INC. and any of its
        affiliated companies – “RecruitGenius.ai” “we” or “us” recognizes the
        importance of maintaining the privacy and security of personal data that
        we collect.
      </span>
      <div>CVHide Privacy Policy</div>
      <h3>1. Introduction</h3>
      <span>
        Welcome to CVHide ("CVHide", "we", "us", "our"). This Privacy Policy
        ("Policy") explains how we collect, use, and disclose your information
        when you use our website and services ("Services"). Please read this
        Policy carefully before using the Services. By accessing or using the
        Services, you agree to be bound by this Policy.
      </span>
      <h3>2. Information We Collect</h3>
      <span>We collect the following information from you:</span>
      <ul className="list-disc list-inside">
        <li>
          Personal information: This includes your name, email address, phone
          number, and any other information you provide to us voluntarily, such
          as your professional background or skills.
        </li>
        <li>
          Technical information: This includes your IP address, browser type,
          operating system, and information about your use of the Services, such
          as the pages you visit and the features you use.
        </li>
        <li>
          Content information: This includes the CVs, resumes, and other
          documents you upload to the Services.
        </li>
      </ul>
      <h3>3. How We Use Your Information</h3>
      <span>We use your information for the following purposes:</span>
      <ul className="list-disc list-inside">
        <li> To provide and operate the Services. </li>
        <li> To personalize and improve your experience with the Services. </li>
        <li>
          To send you important information about the Services, such as updates,
          announcements, and promotional offers.
        </li>
        <li>To respond to your inquiries and requests.</li>
        <li> To analyze and improve the Services. </li>
        <li> To comply with legal requirements.</li>
      </ul>
      <h3>4. How We Share Your Information</h3>
      <span>
        We may share your information with the following third parties:
      </span>
      <ul>
        <li>
          Service providers: We may share your information with service
          providers who help us operate the Services, such as cloud storage
          providers and data analytics providers.
        </li>
        <li>
          Legal and regulatory authorities: We may share your information with
          law enforcement agencies or other government officials if required by
          law or to comply with a legal process.
        </li>
        <li>
          Other third parties: We may share your information with other third
          parties with your consent.
        </li>
      </ul>
      <h3>5. Your Choices</h3>
      <span>You have the following choices regarding your information:</span>
      <ul className="list-disc list-inside">
        <li>
          You can access and update your personal information in your account
          settings.
        </li>
        <li>
          You can unsubscribe from marketing emails by clicking the
          "unsubscribe" link in the email.
        </li>
        <li>
          You can request to delete your account and your information by
          contacting us at hello@recruitgenius.ai.
        </li>
      </ul>
      <h3>6. Data Security</h3>
      <span>
        We take reasonable measures to protect your information from
        unauthorized access, disclosure, alteration, or destruction. However, no
        security measures are perfect, and we cannot guarantee the security of
        your information.
      </span>
      <h3>7. International Transfers</h3>
      <span>
        Your information may be transferred to and processed in countries other
        than your own. These countries may have different data protection laws
        than your own country.
      </span>
      <h3>8. Children's Privacy</h3>
      <span>
        The Services are not intended for children under the age of 13. We do
        not knowingly collect personal information from children under the age
        of 13. If you are a parent or guardian and you are aware that your child
        has provided us with personal information, please contact us.
      </span>
      <h3>9. Changes to this Policy</h3>
      <span>
        We may update this Policy from time to time. We will notify you of any
        changes by posting the new Policy on the Services. Your continued use of
        the Services after the revised Policy is posted constitutes your
        acceptance of the revised Policy.
      </span>
      <h3>10. Contact Us</h3>
      <span>
        If you have any questions about this Policy, please contact us at
        hello@recruitgenius.ai.
      </span>
    </div>
  );
}

export default Privacy;
