import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { Button, message, notification, Upload } from "antd";
import Icon, { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import type { UploadFile } from "antd/es/upload/interface";

import { DeleteOutlined } from "@ant-design/icons";
import type { RootState } from "../../store";

import HeroGIF from "../../assets/hide-the-information.gif";

// import { decrement, increment } from './counterSlice'
// import styles from './index.module.css'
import logo from "../../assets/positive-logo.svg";
// import { useHideCVInfoMutation } from "../../services/pdfAPI";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { NotificationPlacement } from "antd/es/notification/interface";
import Context from "@ant-design/icons/lib/components/Context";
import useSendCV from "../../hooks/useSendCV";

const UploadCV: React.FC = () => {
  const count = useAppSelector((state: RootState) => state.counter.value);
  const dispatch = useAppDispatch();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (
    placement: NotificationPlacement,
    status: String,
    detail: String
  ) => {
    api.info({
      message: `Error ${status}`,
      description: <Context.Consumer>{() => detail}</Context.Consumer>,
      placement,
    });
  };

  const { isLoading, sendCV } = useSendCV(openNotification);

  const customRequest = async (options: UploadRequestOption) => {
    sendCV(options.file as Blob);
  };

  const [file, setFile] = useState<Blob>();

  const { Dragger } = Upload;

  // const submitFile = async (e: Event | MouseEvent) => {
  //   try {
  //     e.stopPropagation();
  //     let formData = new FormData();
  //     if (!file) {
  //       openNotification(
  //         "bottomRight",
  //         "file is empty",
  //         "Please check your input"
  //       );
  //       return;
  //     }
  //     formData.append("file", file!);
  //     // RTK Query
  //     // const response = await hideCV(form);
  //     // if (response.error) {
  //     //   openNotification(
  //     //     "bottomRight",
  //     //     response.error.status,
  //     //     response.error.status === 400
  //     //       ? "File is invalid. Please check your input"
  //     //       : response.error.data.detail
  //     //   );
  //     // } else {
  //     //   console.log(response);
  //     //   // const blob = await response.data.blob();

  //     var requestOptions = {
  //       method: "POST",
  //       body: formData,
  //     };

  //     fetch(
  //       `${
  //         import.meta.env.VITE_REACT_APP_FILE_PROCESSOR_DOMAIN
  //       }/parse-pdf-ocr/ocr-hide-infor-add-wartermark`,
  //       requestOptions
  //     )
  //       .then(async (response) => {
  //         console.log("ahihi");
  //         if (response.status !== 200) {
  //           throw new Error(response.status.toString());
  //         }
  //         const blob = await response.blob();

  //         // const file = new Blob([blob], {
  //         //   type: "application/pdf",
  //         // });

  //         // const link = document.createElement("a");
  //         // link.href = window.URL.createObjectURL(file);

  //         const link = document.createElement("a");
  //         link.href = window.URL.createObjectURL(blob);
  //         link.download = "InfoHiddenCV.png";
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //       })
  //       .catch((e: Error) => {
  //         openNotification(
  //           "bottomRight",
  //           e.message,
  //           e.message === "400"
  //             ? "File is invalid. Please check your input"
  //             : `Error ${e.message}`
  //         );
  //       });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handleChange: UploadProps["onChange"] = ({ file, fileList }) => {
    file.status = "done";
  };

  const props: UploadProps = {
    name: "file",
    maxCount: 1,
    multiple: false,
    listType: "picture",
    onChange: handleChange,
    previewFile(file) {
      return new Promise(() => setFile(file));
    },
    showUploadList: {
      removeIcon: <DeleteOutlined onClick={() => setFile(undefined)} />,
    },
    customRequest: customRequest,
  };

  return (
    <div>
      <div
        id="UploadCV"
        className="UploadCV  hidden md:flex flex-col space-y-10"
      >
        {/* <div className="w-full h-[60px] ">
          <img src={logo} className="w-[200px] h-[60px] ml-4 mt-5" />
        </div> */}
        <div className="grid grid-cols-12 grid-rows-12">
          <img
            className="col-start-2 col-span-4 row-span-5"
            src={HeroGIF}
            alt="Hide CV Information"
          />
          <div className=" col-start-7 col-span-5 row-span-8 grid grid-cols-12 grid-rows-12">
            <div className="col-span-11 italic row-span-1">
              Tired of Talent Leaks? Blind CV Sharing is Here to Save Your Day.
            </div>
            <div className="col-span-11 row-start-3 row-span-2 text-5xl font-medium bg-gradient-to-br from-[#0A6ABF] from-5% via-[#5E2ED6] via-60% to-[#5FFBF1] to-100% inline-block text-transparent bg-clip-text">
              <div className="font-extrabold">
                Headhunter Hustle Hack: Blindly Share
              </div>
              <div className="font-extrabold">
                Top Talent, Keep Rivals in the Dark.
              </div>
            </div>
            <div className="col-span-11 row-start-6 row-span-1">
              CVHide is a web-based tool that protects candidate personal
              information on the CV by applying a subtle watermark.
            </div>
          </div>
          <div className="col-start-7 col-span-5 row-start-9 row-span-4 grid grid-cols-6 grid-rows-6">
            <div className="col-span-6 row-span-4">
              <Dragger
                {...props}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#ffffff",
                }}
              >
                <div className="grow self-center text-gray-800 opacity-50">
                  Drag & Drop the CVs here
                </div>
              </Dragger>
            </div>
            {/* <button
              className="z-1000 col-start-6 row-start-6 rounded-full rounded-md  bg-[#0A6ABF] text-white font-medium hover:none"
              onClick={submitFile}
            >
              Submit
            </button> */}
          </div>
        </div>
        <div className="grow"></div>
      </div>
      <div className="UploadCV-mobile flex md:hidden flex-col space-y-10">
        <div className="grid grid-cols-12">
          <div className="col-start-2 col-span-10 flex justify-items-end">
            <div>
              <img className="" src={HeroGIF} alt="Hide CV Information" />
            </div>
          </div>
          <div className="mt-6 col-start-2 col-span-10  grid grid-cols-12 grid-rows-12">
            <div className="col-span-11 italic row-span-1">
              Tired of Talent Leaks? Blind CV Sharing is Here to Save Your Day.
            </div>
            <div className="mt-2 col-span-11 row-start-3 row-span-2 text-4xl font-medium bg-gradient-to-br from-[#0A6ABF] from-5% via-[#5E2ED6] via-60% to-[#5FFBF1] to-100% inline-block text-transparent bg-clip-text">
              <div className="font-extrabold">
                Headhunter Hustle Hack: Blindly Share
              </div>
              <div className="font-extrabold">
                Top Talent, Keep Rivals in the Dark.
              </div>
            </div>
            <div className="mt-2 col-span-11 row-start-6 row-span-1">
              CVHide is a web-based tool that protects candidate personal
              information on the CV by applying a subtle watermark.
            </div>
          </div>
          <div className="mt-4 col-start-2 col-span-10 grid grid-rows-6">
            <div className="row-span-6 h-[100px]">
              <Dragger
                {...props}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#ffffff",
                }}
              >
                <div className="grow self-center text-gray-800 opacity-50">
                  Drag & Drop the CVs here
                </div>
              </Dragger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UploadCV;
