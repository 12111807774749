import React from "react";
import { Provider as ReduxStoreProvider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Router } from "react-router";
import { createBrowserHistory } from "history";

import "./features/Counter/index.module.css";
import Counter from "./features/Counter/index";
import DocumentList from "./features/DocumentList";
// import { history, store } from './store'
import { store } from "./store";
import UploadCV from "./features/UploadCV";
import ErrorBoundary from "./components/ErrorBoundary";
import TermAndCondition from "./features/TermAndCondition";
import Privacy from "./features/Privacy";
import Home from "./features/Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        index: true,
        element: <UploadCV />,
      },
      {
        path: "/term-and-condition",
        element: <TermAndCondition />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },
    ],
  },
]);

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <ReduxStoreProvider store={store}>
        <RouterProvider router={router} />
      </ReduxStoreProvider>
    </ErrorBoundary>
  );
};

export default App;
